.header {
  top: 0;
  z-index: 100;
}

.header a {
  float: left;
  color: white;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-size: 18px;
  line-height: 15px;
  border-radius: 4px;
}

.header a.active {
  color: #686767;
}

.header .active:hover {
  background-color: #686767;
  color: white;
}

.header a.project {
  background-color: rgb(37, 121, 204);
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}

.navbar {
  /* background: linear-gradient(-45deg, #1f1527, #150e11, #1f2d32, #292646); */
  background: #df92a7;
}

.navbar_rounded {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
  border-radius: 25px;
}
